require("@fontsource/montserrat/latin-500.css")
require("@fontsource/montserrat/latin-300.css")
require("@fontsource/montserrat/latin-700.css")
require("@fontsource/montserrat/latin-400.css")
require("@fontsource/montserrat/latin-600.css")

const Sentry = require("@sentry/gatsby")

exports.onClientEntry = () => {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: process.env.SENTRY_RELEASE,
  })
}

exports.onServiceWorkerUpdateReady = () => {
  if (typeof window !== "undefined") {
    window.location.reload()
  }
}

exports.shouldUpdateScroll = ({
  routerProps: {
    location: { hash },
  },
}) => {
  if (typeof window !== "undefined") {
    if (hash && document.getElementById("beginning")) {
      document.querySelector("#gatsby-focus-wrapper").style.scrollBehavior = "smooth"
      const topOffset = document.getElementById(hash.replace("#", ""))?.offsetTop
      document.querySelector("#gatsby-focus-wrapper").scrollTop = topOffset + 80
    } else {
      document.querySelector("#gatsby-focus-wrapper").style.scrollBehavior = "unset"
      document.querySelector("#gatsby-focus-wrapper").scrollTop = 0

      if (document.getElementById("beginning")) {
        document.querySelector("#gatsby-focus-wrapper").style.scrollBehavior = "smooth"
      }
    }
  }
  return false
}
